import { ConfigProvider } from 'antd';
import locale from 'antd/locale/zh_CN';
import { StrictMode } from 'react';
import LogtoContainer from './LogtoContainer';
import AppRouter from './router';

export function Entry() {
	return (
		<StrictMode>
			<ConfigProvider locale={locale}>
				<LogtoContainer app="apartment">
					<AppRouter />
				</LogtoContainer>
			</ConfigProvider>
		</StrictMode>
	);
}
