import {
	generateCodeChallenge,
	generateCodeVerifier,
	generateState,
} from '@logto/browser';
import { fetchOidcConfig, generateSignInUri } from '@logto/js';
import { useMount } from 'ahooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { woodLogtoClient, woodLogtoConfig } from '../../LogtoContainer';
import style from '../../page/account/style.module.css';
import { getDiscoveryEndpoint } from './getDiscoveryEndpoint';

const SignIn = () => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();

	useMount(async () => {
		const state = generateState();
		const codeVerifier = generateCodeVerifier();
		const codeChallenge = await generateCodeChallenge(codeVerifier);

		const discoveryPath = getDiscoveryEndpoint(woodLogtoConfig.endpoint);
		const OidcConfig = await (await fetch(discoveryPath)).json();
		const authorizationEndpoint = OidcConfig.authorization_endpoint;

		const signConfig = {
			authorizationEndpoint: authorizationEndpoint,
			prompt: woodLogtoConfig.prompt,
			resources: woodLogtoConfig.resources,
			scopes: woodLogtoConfig.scopes,
			clientId: woodLogtoConfig.appId,
			redirectUri: `${location.origin}/wood/callback`,
			state: state,
			codeChallenge: codeChallenge,
		};
		const signUrl = generateSignInUri(signConfig);

		const signInSession = {
			redirectUri: signConfig.redirectUri,
			postRedirectUri: '',
			state,
			codeVerifier,
		};

		localStorage.setItem('p-signInSession', JSON.stringify(signInSession));
		// this.adapter.setStorageItem(types.PersistKey.SignInSession, value && JSON.stringify(value));
		// window.location.replace(signUrl);
		// window.location.assign(signUrl);

		location.href = signUrl;
	});

	return <div className={style.container}>登陆中</div>;
};

export { SignIn };
