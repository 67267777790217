import { useMount } from 'ahooks';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { logtoClient, woodLogtoClient } from '../../LogtoContainer';
import style from '../../page/account/style.module.css';

// const signOutUrl = `${local}/account/sign-in`;
const SignOut = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();

	useMount(() => {
		// if (!isAuthenticated) {
		//   navigate('/account/sign-in');
		// }

		// const signOutUrl = searchParams.get('url');
		// if (!signOutUrl) {
		// 	throw new Error('missing signOutUrl');
		// }

		sessionStorage.clear();
		location.href = `${location.origin}/wood/stock`;
	});

	return (
		<div className={style.container}>
			<div>退出登录</div>
		</div>
	);
};

export { SignOut };
