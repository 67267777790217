import { QueryClientProvider } from '@tanstack/react-query';
import { lazy } from 'react';
import PartReturnedPurchase from './PartReturnedPurchase';
import { SignIn } from './SignIn';
import Callback from './callback';
import { SignOut } from './signOut';
import { queryClient } from '../../utils/trpc';

const WoodContainer = lazy(() => import('./WoodContainer'));
const MyTeam = lazy(() => import('./MyTeam'));
const Stock = lazy(() => import('./Stock'));
const Sell = lazy(() => import('./Sell'));
const CostSetting = lazy(() => import('./CostSetting'));
const Transaction = lazy(() => import('./Transaction'));
const SortSetting = lazy(() => import('./SortSetting'));
const TransactionEdit = lazy(() => import('./TransactionEdit'));
const MyInfo = lazy(() => import('./myInfo'));
const StockStat = lazy(() => import('./StockStat'));
const TransactionStat = lazy(() => import('./TransactionStat'));

const WoodRouter = [
	{ path: '/wood/signIn', element: <SignIn /> },
	{ path: '/wood/signOut', element: <SignOut /> },
	{ path: '/wood/callback', element: <Callback /> },
	{
		path: 'wood',
		element: (
			<QueryClientProvider client={queryClient}>
				<WoodContainer />
			</QueryClientProvider>
		),
		children: [
			{ path: 'stock', element: <Stock /> },
			{ path: 'sell', element: <Sell /> },
			{ path: 'transaction', element: <Transaction /> },
			{ path: 'costSetting', element: <CostSetting /> },
			{ path: 'transactionEdit', element: <TransactionEdit /> },
			{ path: 'sortSetting', element: <SortSetting /> },
			{ path: 'myInfo', element: <MyInfo /> },
			{ path: 'stockStat', element: <StockStat /> },
			{ path: 'transactionStat', element: <TransactionStat /> },
			{ path: 'partReturnedPurchase', element: <PartReturnedPurchase /> },
			{ path: 'myTeam', element: <MyTeam /> },
		],
	},
];

export default WoodRouter;
