import { Card, Form, Tabs } from 'antd/es';
import TextArea from 'antd/es/input/TextArea';
import { format } from 'prettier';
import htmlPlugin from 'prettier/plugins/html';
import { useEffect, useState } from 'react';
import { useFormLocalState } from '../useFormLocalState';
export default function FilterDocument() {
	// const entries = Object.entries(targetInfo.RecordList);
	const [form] = Form.useForm();
	const { doc, setDoc } = useFormLocalState();
	const [fineDoc, setFineDoc] = useState('');
	//   const [fineStyle, setFineStyle] = useState();
	// @ts-ignore
	useEffect(() => {
		async function filterAction() {
			const regex = /<style.*>(\n|.)*?<\/style>/g;
			const scriptRegex = /<script.*>(\n|.)*?<\/script>/g;

			const text =
				doc.replaceAll(regex, '').replaceAll(scriptRegex, '') +
				doc.match(regex)?.join('');

			try {
				const formattedContent = await format(text ?? '', {
					parser: 'html',
					plugins: [htmlPlugin],
				});
				setFineDoc(formattedContent);
			} catch (err) {
				setFineDoc(text);
			}
			return () => {};
		}

		filterAction();
	}, [doc]);

	return (
		<div>
			<Tabs
				className="px-5"
				items={[
					{
						key: 'filter style',
						label: 'filter style',
						children: (
							<div>
								<Card className="w-full" title="filter style">
									<Form
										form={form}
										initialValues={{ doc: doc }}
										onValuesChange={(a) => {
											a.doc && setDoc(a.doc);
										}}
										onFinish={async (v) => {}}
									>
										<Form.Item
											name="doc"
											label="doc"
											rules={[{ required: true }]}
										>
											<TextArea />
										</Form.Item>

										<pre>{fineDoc}</pre>
									</Form>
								</Card>
							</div>
						),
					},
				]}
			/>
		</div>
	);
}
