import { create } from 'zustand';
import { combine, createJSONStorage, persist } from 'zustand/middleware';
import { ActionType } from './ActionType';

export type resType = {
	action: ActionType;
};

export const constActionType: resType[] = [
	{
		action: ActionType.create,
	},
	{
		action: ActionType.read,
	},
	{
		action: ActionType.update,
	},
	{
		action: ActionType.delete,
	},
	{
		action: ActionType.list,
	},
];

export type formDataType = {
	tableName: string;
	keyField: string;
	statusField: string;
	filePath: string;
};

export const useFormLocalState = create(
	persist(
		combine(
			{
				database: undefined as undefined | string,
				table: undefined as undefined | string,
				schema: undefined as undefined | string,
				formState: {
					tableName: '',
					filePath: '',
				} as formDataType,
				doc: '',
				i18n: '',
			},
			(set) => ({
				setDatabase: (v: string) => set({ database: v }),
				setSchema: (v: string) => set({ schema: v }),
				setTable: (v: string) => set({ table: v }),
				setFormState: (v: object) =>
					set((state) => ({ formState: { ...state.formState, ...v } })),
				setDoc: (v: string) => set({ doc: v }),
				setI18n: (v: string) => set({ i18n: v }),
			}),
		),
		{
			name: 'database-storage', // name of the item in the storage (must be unique)
			storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
		},
	),
);
