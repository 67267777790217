import { TrpcBase } from '@/utils/trpc';

export default function Info() {
	const infoApi = TrpcBase.note.queryInfo.useQuery({
		opType: 'info',
		params: [],
	});

	const targetInfo = infoApi.data?.rows?.[0];
	if (!targetInfo) {
		return <div>loading</div>;
	}

	const entries = Object.entries(targetInfo);

	return (
		<div>
			{entries?.map((i) => {
				return (
					<div className="px-2 py-1" key={i[0]}>
						{i[0]}: {i[1] as string}
					</div>
				);
			})}
		</div>
	);
}
