import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Outlet, useNavigate } from 'react-router-dom';

import { lazy } from 'react';
const Convert = lazy(() => import('./convert'));

const queryClient = new QueryClient();

function BiliLayout() {
	const navigate = useNavigate();

	return (
		<QueryClientProvider client={queryClient}>
			<Outlet />
		</QueryClientProvider>
	);
}

export const BiliEntry = [
	{
		path: 'bili',
		element: <BiliLayout />,
		children: [
			{
				path: '',
				element: <Convert />,
			},
		],
	},
];
