import { InfoOutlined } from '@ant-design/icons';
import { TrpcBase } from '../../../utils/trpc';
import { Select } from 'antd';
import { useFormLocalState } from '../useFormLocalState';

export function DatabasesList() {
	const info = TrpcBase.note.queryInfo.useQuery({
		opType: 'databases',
	});
	const { database, setDatabase } = useFormLocalState();

	return (
		<Select
			className="w-40"
			value={database}
			options={info.data?.rows?.map((i) => {
				return { label: i?.datname, value: i?.datname };
			})}
			onChange={(v) => {
				setDatabase(v);
			}}
		/>
	);
}
