import { TrpcBase } from '../../../utils/trpc';
import { Button, Form, message, Table } from 'antd';
import { Input } from 'antd/lib';
import dayjs from 'dayjs';

export default function () {
	const list = TrpcBase.qiniu.list.useQuery();
	const uploadCert = TrpcBase.qiniu.uploadCert.useMutation();
	const delCert = TrpcBase.qiniu.delCert.useMutation();
	const certToSite = TrpcBase.qiniu.certToSite.useMutation();

	return (
		<div>
			<div className="flex flex-row gap-2 m-3">
				<Form
					onFinish={async (v) => {
						try {
							const res = await uploadCert.mutateAsync(v);
							console.log(res);
						} catch (err) {
							console.log(err);

							if (err?.message) {
								message.error(err?.message);
							}
						}

						list.refetch();
					}}
				>
					<Form.Item label="域名全称" name="name">
						<Input />
					</Form.Item>

					<Button htmlType="submit">上传</Button>
				</Form>

				<Button
					onClick={() => {
						list.refetch();
					}}
				>
					刷新
				</Button>
			</div>
			<Table
				dataSource={list.data?.certs}
				columns={[
					{
						title: '通用名称',
						dataIndex: 'name',
					},
					{
						title: '通用名称',
						dataIndex: 'common_name',
					},
					{
						title: 'certid',
						dataIndex: 'certid',
					},
					{
						title: 'encrypt',
						dataIndex: 'encrypt',
					},
					{
						title: '创建时间',
						dataIndex: 'create_time',
						render: (v) => dayjs(v * 1000).format('YYYY-MM-DD HH:mm'),
					},
					{
						title: 'product_short_name',
						dataIndex: 'product_short_name',
					},
					{
						title: '生效时间',
						dataIndex: 'not_before',
						render: (v) => dayjs(v * 1000).format('YYYY-MM-DD HH:mm'),
					},
					{
						title: '过期时间',
						dataIndex: 'not_after',
						render: (v) => dayjs(v * 1000).format('YYYY-MM-DD HH:mm'),
					},
					{
						title: 'enable',
						dataIndex: 'enable',
						render: (a) => a.toString(),
					},
					{
						title: 'op',
						dataIndex: 'certid',
						render: (ctId) => {
							return (
								<Button
									onClick={async () => {
										const res = await delCert.mutateAsync({
											certId: ctId,
										});
										console.log(res);

										if (res?.error) {
											message.error(res?.error);
										}
										list.refetch();
									}}
								>
									del
								</Button>
							);
						},
					},
				]}
			/>

			<div className="m-3" style={{ width: 350 }}>
				<Form
					onFinish={async (v) => {
						try {
							const res = await certToSite.mutateAsync(v);
							if (res.code === 200) {
								message.success('200 成功');
							}
							if (res?.error) {
								message.error(res.error);
							}
						} catch (err) {
							console.log(err);

							if (err?.message) {
								message.error(err?.message);
							}
						}
					}}
				>
					<Form.Item name="name" label="域名全称">
						<Input />
					</Form.Item>
					<Form.Item name="certId" label="certId">
						<Input />
					</Form.Item>

					<Form.Item noStyle>
						<Button htmlType="submit">submit</Button>
					</Form.Item>
				</Form>
			</div>
		</div>
	);
}
