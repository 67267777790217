import { useDeepCompareEffect, useLocalStorageState } from 'ahooks';
import { Button, Card, Form, Tabs, message } from 'antd/es';
import TextArea from 'antd/es/input/TextArea';
import { snakeCase } from 'lodash-es';
import { useCallback, useRef, useState } from 'react';
import { useCopyFunc } from '../../apartment/print/useCopyFunc';
import { useFormLocalState } from '../useFormLocalState';

export default function I18nKey() {
	useCopyFunc();

	const [form] = Form.useForm();
	const { i18n, setI18n } = useFormLocalState();
	const [fineDoc, setFineDoc] = useState('');
	const [keySet, setKeySet] = useLocalStorageState<Record<string, string>>(
		'enKey',
		{},
	);
	const [trans, setTransSet] = useState<Record<string, string>>({});
	const tep = useRef<Record<string, string>>({});
	//   const [fineStyle, setFineStyle] = useState();
	// @ts-ignore

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const filterAction = useCallback(async () => {
		//   const regex = /<span.*>(\n|.)*?<\/span>/g;
		//   console.log(i18n.match(regex));
		console.log('运行几次', keySet);
		const target = document.querySelector('#target-i18n');

		const sub = [
			'span',
			'div',
			'a',
			'h1',
			'h2',
			'h3',
			'h4',
			'h5',
			'p',
			'strong',
			'li',
		];
		let all: Element[] = [];

		for (const v of sub) {
			const tmp = document.querySelectorAll(`#target-i18n ${v}`);
			all = all.concat(Array.from(tmp));
		}

		if (all) {
			const sp = all.filter((i) => i?.children?.length === 0);

			console.log(sp);
			sp.map((a, i) => {
				let myText = '';

				if (a?.innerText !== '') {
					myText = a?.innerText;
				}
				if (myText === '') {
					myText = a?.innerHTML;
				}
				if (
					myText &&
					a.getAttribute('enKey') === null &&
					myText.includes('{{') === false
				) {
					const text = myText.trim();
					a.setAttribute('label', text);
					a.setAttribute('enKey', snakeCase(text));

					tep.current = { ...tep.current, [snakeCase(text)]: text };

					a.innerHTML = `{{$t('${a.getAttribute('enKey')}')}}`;
				}

				return a;
			}).map((d) => {
				d.removeAttribute('label');
				d.removeAttribute('enKey');

				return d.outerHTML;
			});
		}

		//   const text = i18n.replaceAll(regex, '') + i18n.match(regex)?.join('');
		//   const formattedContent = await format(target?.innerHTML ?? '', {
		//     parser: 'html',
		//     plugins: [htmlPlugin],
		//   });
		setFineDoc(target?.innerHTML ?? '');
		setKeySet({ ...keySet, ...tep.current });
		return () => {};
	}, [setKeySet, setFineDoc]);

	async function transAction() {
		//   const regex = /<span.*>(\n|.)*?<\/span>/g;
		//   console.log(i18n.match(regex));
		const zhKey: Record<string, string> = {};
		for (const idx in keySet) {
			const v = keySet[idx];
			if (v) {
				zhKey[idx] = (await translate(v)).translatedText;
			}
		}

		setTransSet(zhKey);
		return () => {};
	}

	return (
		<div>
			<Tabs
				className="px-5"
				items={[
					{
						key: 'get en key',
						label: 'get en key',
						children: (
							<div>
								<Card className="w-full" title="get en key">
									<Form
										form={form}
										initialValues={{ doc: i18n }}
										onValuesChange={(a) => {
											a.doc && setI18n(a.doc);
										}}
										onFinish={async (v) => {}}
									>
										<div className="grid grid-cols-2">
											<Form.Item
												name="doc"
												label="doc"
												rules={[{ required: true }]}
											>
												<TextArea rows={15} />
											</Form.Item>
											<Form.Item name="enKey" label="enKey">
												<TextArea rows={15} />
											</Form.Item>
										</div>

										{/* biome-ignore lint/a11y/useButtonType: <explanation> */}
										<button
											onClick={() => {
												filterAction();
											}}
										>
											获取key
										</button>
										{fineDoc !== '' && (
											<Button
												type="link"
												onClick={() => {
													message.success('复制成功');
												}}
												className={'copy'}
												data-clipboard-text={`${fineDoc}`}
											>
												复制
											</Button>
										)}
										{Object.keys(trans ?? {})?.length !== 0 && (
											<Button
												type="link"
												onClick={() => {
													message.success('复制成功');
												}}
												className={'copy'}
												data-clipboard-text={JSON.stringify({
													en: keySet,
													zh: trans,
												})}
											>
												复制
											</Button>
										)}

										<div className="break-all">{JSON.stringify(keySet)}</div>
										{/* biome-ignore lint/a11y/useButtonType: <explanation> */}
										<button
											onClick={() => {
												// 运行翻译
												transAction();
											}}
										>
											transAction
										</button>
										<div className="break-all">
											{JSON.stringify(trans, null, 2)}
										</div>
									</Form>
								</Card>
							</div>
						),
					},
				]}
			/>

			{/* biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation> */}
			<div id="target-i18n" dangerouslySetInnerHTML={{ __html: i18n }} />
		</div>
	);
}

async function translate(text: string) {
	const res = await fetch('http://127.0.0.1:5000/translate', {
		method: 'POST',
		body: JSON.stringify({
			q: text,
			source: 'auto',
			target: 'zh',
			format: 'text',
			api_key: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
		}),
		headers: { 'Content-Type': 'application/json' },
	});

	return await res.json();
}
