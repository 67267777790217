import { lazy } from 'react';

const ApartmentContainer = lazy(() => import('./ApartmentContainer'));
const UsedByMonth = lazy(() => import('./stat/UsedByMonth'));
const CheckRead = lazy(() => import('./stat/CheckRead'));
const CheckUsed = lazy(() => import('./stat/CheckUsed'));
const RoomHistory = lazy(() => import('./roomHistory/RoomHistory'));
const HelpDraw = lazy(() => import('./helpDraw'));
const MyInfo = lazy(() => import('./myInfo'));

const ElectronChart = lazy(() => import('./electronChart/ElectronChart'));
const Stat = lazy(() => import('./stat/index'));
const Overview = lazy(() => import('./overview/index'));
const ApartmentEdit = lazy(() => import('./edit/edit'));
const ApartmentIndex = lazy(() => import('./index/index'));
const ApartmentList = lazy(() => import('./list/index'));
const ApartmentLogin = lazy(() => import('./login'));
const ApartmentPrint = lazy(() => import('./print/print'));
const ApartmentFlow = lazy(() => import('./flow/index'));
const Check = lazy(() => import('./check/index'));
const RoomConfigure = lazy(() => import('./roomConfigure'));
const BuildingConfigure = lazy(() => import('./buildingConfigure/index'));
const BuildingTeam = lazy(() => import('./buildingTeam/index'));
const DevInfoContact = lazy(() => import('./overview/DevInfoContact'));
const RecordCheck = lazy(() => import('./recordCheck'));
const CheckBill = lazy(() => import('./checkBill'));
// import A from './recordCheck'
const ApartmentRouter = [
	{
		path: 'check',
		element: <Check />,
	},
	{
		path: 'apartment',
		element: <ApartmentContainer />,
		children: [
			{ path: 'buildingTeam/:id', element: <BuildingTeam /> },
			{
				path: 'buildingConfigure',
				element: <BuildingConfigure />,
			},
			{
				path: 'roomConfigure/:id',
				element: <RoomConfigure />,
			},
			{
				path: '',
				element: <ApartmentIndex />,
			},
			{
				path: 'login',
				element: <ApartmentLogin />,
			},
			{
				path: 'flow/used',
				element: <ApartmentFlow type="used" />,
			},
			{
				path: 'flow/amount',
				element: <ApartmentFlow type="amount" />,
			},
			{
				path: 'list',
				element: <ApartmentList />,
			},
			{
				path: 'print',
				element: <ApartmentPrint />,
			},
			{
				path: 'edit',
				element: <ApartmentEdit />,
			},
			{
				path: 'entering',
				element: <ApartmentEdit />,
			},
			{
				path: 'roomHistory',
				element: <RoomHistory />,
			},
			{
				path: 'overview',
				element: <Overview />,
			},
			{
				path: 'checkUsed',
				element: <CheckUsed />,
			},
			{
				path: 'checkRead',
				element: <CheckRead />,
			},
			{
				path: 'recordCheck',
				element: <RecordCheck />,
			},
			{
				path: 'usedByMonth',
				element: <UsedByMonth />,
			},
			{
				path: 'stat',
				element: <Stat />,
			},
			{
				path: 'checkBill',
				element: <CheckBill />,
			},

			{
				path: 'electronChart',
				element: <ElectronChart />,
			},
			{ path: 'helpDraw', element: <HelpDraw /> },
			{ path: 'myInfo', element: <MyInfo /> },
			{ path: 'about', element: <DevInfoContact /> },
		],
	},
];

export default ApartmentRouter;
