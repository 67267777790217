import { Suspense, lazy } from 'react';

import { NoteContextComponent } from './modules/note/pc/NoteContext';
import TRPCLayout from './utils/TRPCLayout';
// import MobileNotePage from './page/mobile/note';
// import NoteListPCPage from './page/pc/note';
const MobileNotePage = lazy(() => import('./page/mobile/note'));
const NoteListPCPage = lazy(() => import('./page/pc/note'));
const NoteCalendarModule = lazy(() => import('./modules/note/pc/calendar'));

const loadingUI = <div className="m-container">loading</div>;

const noteRouter = [
	{
		path: 'note',
		element: (
			<TRPCLayout app="note">
				<NoteListPCPage />
			</TRPCLayout>
		),
	},

	{
		path: 'calendar',
		element: (
			<TRPCLayout app="note">
				<NoteContextComponent>
					<NoteCalendarModule />
				</NoteContextComponent>
			</TRPCLayout>
		),
	},
	{
		path: 'mobile/note',
		element: (
			<TRPCLayout app="note">
				<MobileNotePage />
			</TRPCLayout>
		),
	},
];

export default noteRouter;
