import { isString } from 'lodash-es';
import TableSchema, { type statsType } from '../table/TableSchema';
import { useFormLocalState } from '../useFormLocalState';
import { DatabasesList } from './DatabasesList';
import { TrpcBase } from '../../../utils/trpc';

export default function Database() {
	const {
		database,
		schema,
		setTable,
		table,
		formState,
		setFormState,
		setSchema,
	} = useFormLocalState();

	const writeContent = TrpcBase.note.writeContent.useMutation();
	const info = TrpcBase.note.getTableColumnType.useQuery(
		{
			tableName: table as string,
		},
		{ enabled: isString(table) },
	);

	const schemasApi = TrpcBase.note.queryInfo.useQuery({
		opType: 'schemas',
		params: [database],
	});

	const tableStatsApi = TrpcBase.note.queryInfo.useQuery({
		opType: 'tables_stats',
		params: [],
	});
	const targetInfo = tableStatsApi.data?.rows as unknown as statsType[];

	console.log('schemas', schemasApi.data);

	return (
		<div className="grid grid-cols-5">
			<div>
				<DatabasesList />
				<div className="px-2 py-1">
					schema:
					<div>
						{schemasApi.data?.rows?.map((i) => {
							return (
								// biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
								<div
									key={i.schemaName}
									onClick={() => {
										setSchema(i.schemaName);
									}}
									className={i.schemaName === schema ? 'text-red-400' : ''}
								>
									{i.schemaName}
								</div>
							);
						})}
					</div>
				</div>
				<div>
					{targetInfo?.map((i) => {
						return (
							// biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
							<div
								key={`${i.schemaName}-${i.tableName}`}
								className={`cursor-pointer px-2 py-1 ${
									i.tableName === table ? 'text-red-400' : ''
								}`}
								onClick={() => {
									setTable(i.tableName);
								}}
							>
								{i.schemaName} {i.tableName}: {i.dataSize}
							</div>
						);
					})}
				</div>
			</div>
			<TableSchema />
		</div>
	);
}
