import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';

import ApartmentRouter from './modules/apartment/ApartmentRouter';
import { BiliEntry } from './modules/bili/entry';

import PageIndex from './PageIndex';
import noteRouter from './noteRouter';
import { SignIn } from './page/account';
import Callback from './page/account/callback';
import { SignOut } from './page/account/logout';
import ErrorPage from './page/error-page';
import { CodeGenEntry } from './modules/codeGen/Entry';
import WoodRouter from './modules/wood/WoodRouter';

const router = createBrowserRouter([
	{
		path: '/',
		id: 'root',
		element: (
			<>
				<Outlet />
				{/* <ScrollRestoration
          getKey={(location, matches) => {
            // default behavior
            return location.key;
          }}
        /> */}
			</>
		),
		errorElement: <ErrorPage />,
		children: [
			{ path: '/', element: <PageIndex /> },
			{
				path: 'account',
				element: (
					<div>
						<Outlet />
					</div>
				),
				children: [
					{
						path: 'sign-in',
						element: <SignIn />,
					},
					{
						path: 'callback',
						element: <Callback />,
					},
					{
						path: 'sign-out',
						element: <SignOut />,
					},
				],
			},
		],
	},
	...CodeGenEntry,
	...noteRouter,
	...ApartmentRouter,
	...BiliEntry,
	...WoodRouter,
]);

export default function AppRouter() {
	return <RouterProvider router={router} />;
}
