import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Outlet, useNavigate } from 'react-router-dom';

import { lazy } from 'react';
import { TrpcBase } from '../../utils/trpc';
import TRPCLayout from '../../utils/TRPCLayout';
import CodeGen from '.';
import Info from './info';
import { Menu } from 'antd';
import Database from './database';
import DnsList from './dnsList';
import FilterDocument from './filterDocument';
import I18nKey from './i18n';
import Qiniu from './qiniu';

const queryClient = new QueryClient();

function CodeGenLayout() {
	const navigate = useNavigate();

	return (
		<TRPCLayout app="note">
			<QueryClientProvider client={queryClient}>
				<Menu
					items={[
						{
							label: 'index',
							key: '/codeGen',
							// icon: <MailOutlined />,
						},
						{
							label: 'database',
							key: '/codeGen/database',
							// icon: <MailOutlined />,
						},
						{
							label: 'info',
							key: '/codeGen/info',
							// icon: <MailOutlined />,
						},
						{
							label: 'dns',
							key: '/codeGen/dns',
							// icon: <MailOutlined />,
						},
						{
							label: 'filter document',
							key: '/codeGen/filterDocument',
							// icon: <MailOutlined />,
						},
						{
							label: 'i18n key',
							key: '/codeGen/i18n',
							// icon: <MailOutlined />,
						},
						{
							label: 'qiniu',
							key: '/codeGen/qiniu',
							// icon: <MailOutlined />,
						},
					]}
					onClick={(e) => {
						console.log('click ', e);
						navigate(e.key);
					}}
					mode="horizontal"
				/>
				<Outlet />
			</QueryClientProvider>
		</TRPCLayout>
	);
}

export const CodeGenEntry = [
	{
		path: 'codeGen',
		element: <CodeGenLayout />,
		children: [
			{
				path: '',
				element: <CodeGen />,
			},
			{
				path: 'database',
				element: <Database />,
			},
			{
				path: 'info',
				element: <Info />,
			},
			{
				path: 'dns',
				element: <DnsList />,
			},
			{
				path: 'filterDocument',
				element: <FilterDocument />,
			},
			{
				path: 'i18n',
				element: <I18nKey />,
			},
			{
				path: 'qiniu',
				element: <Qiniu />,
			},
		],
	},
];
