export default function PageIndex() {
	//   const a1 = performance.now();
	//   let sum = 0;
	//   const stopAt = 1999999999;
	//   for (let i = 0; i < stopAt; i++) {
	//     if (i % 2 === 0) {
	//       sum += i;
	//     }
	//   }
	//   const a2 = performance.now();
	//   const aa = ((a2 - a1) / 1000) | 0;
	//   console.log('time:', aa);

	return (
		<div>
			{/* biome-ignore lint/a11y/useButtonType: <explanation> */}
			<button
				onClick={() => {
					console.log('ok test');
				}}
			>
				AAA
			</button>
			<br />
			12322 <br />
			{/* {sum} */}
		</div>
	);
}
