import { QueryKey, verifyAndParseCodeFromCallbackUri } from '@logto/js';
import { useMount } from 'ahooks';
import { Result, Typography } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import style from '../../page/account/style.module.css';
import { getDiscoveryEndpoint } from './getDiscoveryEndpoint';
import { woodLogtoConfig } from '../../LogtoContainer';

const { Paragraph, Text } = Typography;

export enum TokenGrantType {
	AuthorizationCode = 'authorization_code',
	RefreshToken = 'refresh_token',
}

const ContentType = {
	formUrlEncoded: { 'Content-Type': 'application/x-www-form-urlencoded' },
};

const fetchTokenByAuthorizationCode = async ({
	clientId,
	tokenEndpoint,
	redirectUri,
	codeVerifier,
	code,
	resource,
}: {
	clientId: string;
	tokenEndpoint: string;
	redirectUri: string;
	codeVerifier: string;
	code: string;
	resource?: string;
}) => {
	const parameters = new URLSearchParams();
	parameters.append(QueryKey.ClientId, clientId);
	parameters.append(QueryKey.Code, code);
	parameters.append(QueryKey.CodeVerifier, codeVerifier);
	parameters.append(QueryKey.RedirectUri, redirectUri);
	parameters.append(QueryKey.GrantType, TokenGrantType.AuthorizationCode);
	if (resource) {
		parameters.append(QueryKey.Resource, resource);
	}
	const snakeCaseCodeTokenResponse = await (
		await fetch(tokenEndpoint, {
			method: 'POST',
			headers: ContentType.formUrlEncoded,
			body: parameters.toString(),
		})
	).json();
	return snakeCaseCodeTokenResponse;
};

function Callback() {
	const navigate = useNavigate();
	const [error, setError] = useState<null | Error>(null);

	useMount(async () => {
		try {
			const signInData = localStorage.getItem('p-signInSession');
			if (!signInData) {
				throw new Error('sign_in_session.not_found');
			}
			const signInSession = JSON.parse(signInData);

			const { redirectUri, postRedirectUri, state, codeVerifier } =
				signInSession;
			const code = verifyAndParseCodeFromCallbackUri(
				window.location.href,
				redirectUri,
				state,
			);

			const discoveryPath = getDiscoveryEndpoint(woodLogtoConfig.endpoint);
			const OidcConfig = await (await fetch(discoveryPath)).json();
			const tokenEndpoint = OidcConfig.token_endpoint;

			const { id_token: idToken } = await fetchTokenByAuthorizationCode({
				clientId: woodLogtoConfig.appId,
				tokenEndpoint: tokenEndpoint,
				redirectUri,
				codeVerifier,
				code,
				resource: woodLogtoConfig.resources?.[0],
			});

			if (idToken) {
				// console.log('fetch idToken', idToken);
				sessionStorage.setItem('idToken', idToken);

				setTimeout(() => {
					navigate('/wood/stock');
				}, 1000);
			}
		} catch (err) {
			console.error(err);
			setError(err);
		}
	});

	// const params: any = new Proxy(new URLSearchParams(window.location.search), {
	// 	get: (searchParams, prop) => searchParams.get(prop as any),
	// });
	// const app = localStorage.getItem('app') ?? '';

	// // 当登录认证尚未完成时
	// if (isLoading) {
	//   return <div>正在重定向…</div>;
	// }

	if (error?.message) {
		return (
			<Result
				status="error"
				title="回调错误"
				// extra={[
				//   <Button type="primary" key="console">
				//     Go Console
				//   </Button>,
				//   <Button key="buy">Buy Again</Button>,
				// ]}
			>
				<div className="desc">
					<Paragraph>
						<Text
							strong
							style={{
								fontSize: 16,
							}}
						>
							{error?.message}
						</Text>
					</Paragraph>
				</div>
			</Result>
		);
	}

	return <div className={style.container}>登陆中</div>;
}

export default Callback;
