import { isNil } from 'lodash-es';
import { ActionType } from './ActionType';
import { formDataType } from './useFormLocalState';

export function getActionCode(action: ActionType, data: formDataType) {
	switch (action) {
		case ActionType.create: {
			return `await getKysely.insertInto("${data.tableName}").values({

      }).execute()`;
		}
		case ActionType.delete: {
			if (isNil(data.statusField) || data.statusField === '') {
				return `await getKysely.deleteFrom("${data.tableName}").where("${data.keyField}", "=", uuid).execute()`;
			}

			return `await getKysely.updateTable("${data.tableName}").where("${data.keyField}", "=", uuid).set("chargeStatus", "1").execute()`;
		}
		case ActionType.read: {
			return `await getKysely.selectFrom("${data.tableName}").where("${data.keyField}", "=", uuid).selectAll().execute()`;
		}
		case ActionType.update: {
			return `await getKysely.updateTable("${data.tableName}").where("${data.keyField}", "=", uuid).set("chargeStatus", "1").execute()`;
		}
		case ActionType.list: {
			return `await getKysely.selectFrom("${data.tableName}").limit(10).selectAll().execute()`;
		}
	}
}
