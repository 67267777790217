import {
	Button,
	Card,
	Form,
	Input,
	InputNumber,
	Popconfirm,
	Select,
	Table,
	Tabs,
	message,
} from 'antd/es';

import { isNil } from 'lodash-es';
import { TrpcBase } from '../../../utils/trpc';

export default function DnsList() {
	const [form] = Form.useForm();
	const domain = Form.useWatch('domain', form);
	const listApi = TrpcBase.dns.list.useQuery(domain, {
		enabled: !isNil(domain),
	});
	const add = TrpcBase.dns.add.useMutation();
	const del = TrpcBase.dns.del.useMutation();

	const targetInfo = listApi.data;

	const domainSelectOptions = [
		{
			label: 'laikezhan.com',
			value: 'laikezhan.com',
		},
		{
			label: 'ihook.center',
			value: 'ihook.center',
		},
	];

	const domainDetailList = [
		{
			dataIndex: 'RecordId',
			title: 'RecordId',
			sorter: true,
			width: 100,
		},
		{
			dataIndex: 'Value',
			title: 'Value',
			sorter: true,
			width: 150,
			render: (i) => {
				return (
					<div style={{ width: 150 }} className="break-all">
						{i}
					</div>
				);
			},
		},
		{
			dataIndex: 'Status',
			title: 'Status',
			sorter: true,
			width: 100,
		},
		{
			dataIndex: 'UpdatedOn',
			title: 'UpdatedOn',
			sorter: true,
			width: 100,
		},
		{
			dataIndex: 'Name',
			title: 'Name',
			sorter: true,
			width: 200,
		},
		{
			dataIndex: 'Remark',
			title: 'Remark',
			sorter: true,
			width: 100,
		},
		{
			dataIndex: 'Line',
			title: 'Line',
			sorter: true,
			width: 100,
		},
		{
			dataIndex: 'LineId',
			title: 'LineId',
			sorter: true,
			width: 100,
		},
		{
			dataIndex: 'Type',
			title: 'Type',
			sorter: true,
			width: 100,
		},
		{
			dataIndex: 'Weight',
			title: 'Weight',
			sorter: true,
			width: 50,
		},
		{
			dataIndex: 'MonitorStatus',
			title: 'MonitorStatus',
			sorter: true,
			width: 50,
		},
		{
			dataIndex: 'TTL',
			title: 'TTL',
			sorter: true,
			width: 50,
		},
		{
			dataIndex: 'MX',
			title: 'MX',
			sorter: true,
			width: 50,
		},
		{
			dataIndex: 'Ops',
			title: 'Ops',
			render: (v, record) => {
				const action = async () => {
					const data = {
						Domain: domain,
						RecordId: record.RecordId,
					};

					const res = await del.mutateAsync(data);

					await listApi.refetch();
					message.info(res.RequestId);
				};

				return (
					<Popconfirm title={'confirm ops.'} onConfirm={action}>
						<Button>del</Button>
					</Popconfirm>
				);
			},
		},
	];

	const defaultDomain = 'laikezhan.com';
	const Content = [
		{
			key: 'data',
			label: 'data',
			children: (
				// <BVtable
				// 	height={'80dvh'}
				// 	columns={cols}
				// 	records={targetInfo.RecordList}
				// />
				<Table
					scroll={{ x: true }}
					columns={domainDetailList}
					dataSource={targetInfo?.RecordList ?? []}
					pagination={false}
				/>
			),
		},
		{
			key: 'ops',
			label: 'ops',
			children: (
				<div className="grid grid-cols-3">
					<Card className="w-80" title="add sub domain">
						<Form
							initialValues={{
								Domain: defaultDomain,
								RecordLine: '默认',
							}}
							onFinish={async (v) => {
								const res = await add.mutateAsync(v);

								await listApi.refetch();
								message.info(res.RequestId);
							}}
						>
							<Form.Item
								name="Domain"
								label="Domain"
								rules={[{ required: true }]}
							>
								<Select options={domainSelectOptions} />
							</Form.Item>
							<Form.Item
								name="SubDomain"
								label="SubDomain"
								rules={[{ required: true }]}
							>
								<Input />
							</Form.Item>
							<Form.Item
								name="RecordType"
								label="RecordType"
								rules={[{ required: true }]}
							>
								<Input />
							</Form.Item>
							<Form.Item
								name="RecordLine"
								label="RecordLine"
								rules={[{ required: true }]}
							>
								<Input />
							</Form.Item>
							<Form.Item
								name="Value"
								label="Value"
								rules={[{ required: true }]}
							>
								<Input />
							</Form.Item>
							<Form.Item name="Remark" label="Remark">
								<Input />
							</Form.Item>

							<Form.Item>
								<Button type="primary" htmlType="submit">
									录入
								</Button>
							</Form.Item>
						</Form>
					</Card>

					<Card className="w-80" title="del sub domain">
						<Form
							initialValues={{
								Domain: defaultDomain,
							}}
							onFinish={async (v) => {
								const res = await del.mutateAsync(v);

								await listApi.refetch();
								message.info(res.RequestId);
							}}
						>
							<Form.Item
								name="Domain"
								label="Domain"
								rules={[{ required: true }]}
							>
								<Select options={domainSelectOptions} />
							</Form.Item>
							<Form.Item
								name="RecordId"
								label="RecordId"
								rules={[{ required: true }]}
							>
								<InputNumber />
							</Form.Item>

							<Form.Item>
								<Button type="primary" htmlType="submit">
									del
								</Button>
							</Form.Item>
						</Form>
					</Card>
				</div>
			),
		},
	];
	// const entries = Object.entries(targetInfo.RecordList);

	// const cols = Array.from(
	//   new Set(
	//     targetInfo.RecordList?.flatMap((tp) => {
	//       return Object.keys(tp);
	//     }),
	//   ),
	// ).map((p) => {
	//   return {
	//     field: p,
	//     title: p,
	//     sorter: true,
	//     width: 'auto',
	//   };
	// });

	return (
		<div className="px-5 mt-2">
			<Form
				form={form}
				style={{ width: 300 }}
				initialValues={{ domain: defaultDomain }}
			>
				<Form.Item name="domain" label="domain">
					<Select options={domainSelectOptions} />
				</Form.Item>
			</Form>
			<Tabs items={Content} />
		</div>
	);
}
