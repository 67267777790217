import { useMount } from 'ahooks';
import { Result, Typography } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logtoClient } from '../../LogtoContainer';
const { Paragraph, Text } = Typography;
import style from './style.module.css';

function Callback() {
	const navigate = useNavigate();
	const [error, setError] = useState<null | Error>(null);

	useMount(async () => {
		try {
			await logtoClient.handleSignInCallback(window.location.href);
			navigate(`/${app}`);
		} catch (err) {
			console.error(err);
			setError(err);
		}
	});

	const params: any = new Proxy(new URLSearchParams(window.location.search), {
		get: (searchParams, prop) => searchParams.get(prop as any),
	});
	const app = localStorage.getItem('app') ?? '';

	// // 当登录认证尚未完成时
	// if (isLoading) {
	//   return <div>正在重定向…</div>;
	// }

	if (error?.message) {
		return (
			<Result
				status="error"
				title="回调错误"
				// extra={[
				//   <Button type="primary" key="console">
				//     Go Console
				//   </Button>,
				//   <Button key="buy">Buy Again</Button>,
				// ]}
			>
				<div className="desc">
					<Paragraph>
						<Text
							strong
							style={{
								fontSize: 16,
							}}
						>
							{error?.message}
						</Text>
					</Paragraph>
				</div>
			</Result>
		);
	}

	return <div className={style.container}>登陆中</div>;
}

export default Callback;
