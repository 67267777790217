import { useMount } from 'ahooks';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { logtoClient } from '../../LogtoContainer';
import style from './style.module.css';

// const signOutUrl = `${local}/account/sign-in`;
const SignOut = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();

	useMount(() => {
		// if (!isAuthenticated) {
		//   navigate('/account/sign-in');
		// }

		const signOutUrl = searchParams.get('url');
		if (!signOutUrl) {
			throw new Error('missing signOutUrl');
		}

		logtoClient.signOut(signOutUrl);
	});

	return (
		<div className={style.container}>
			<div>退出登录</div>
		</div>
	);
};

export { SignOut };
