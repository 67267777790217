import { Button, Col, Form, Input, Row } from 'antd';

import { useMount } from 'ahooks';
import { useSearchParams } from 'react-router-dom';
import { TrpcBase } from '../../utils/trpc';
import { getSource } from './getSource';
import {
	constActionType,
	type formDataType,
	useFormLocalState,
} from './useFormLocalState';

export default function CodeGen() {
	const [searchParams, setSearchParams] = useSearchParams();
	const [form] = Form.useForm<formDataType>();
	const { formState, setFormState, database, schema, setSchema } =
		useFormLocalState();
	useMount(() => {
		document.title = 'code gen';
	});

	const tableName = Form.useWatch('tableName', form);

	const writeContent = TrpcBase.note.writeContent.useMutation();
	const info = TrpcBase.note.getTableColumnType.useQuery(
		{
			tableName: tableName,
		},
		{ enabled: tableName !== undefined },
	);

	const schemasApi = TrpcBase.note.queryInfo.useQuery({
		opType: 'schemas',
		params: [database],
	});

	// const tablesApi = TrpcBase.note.queryInfo.useQuery({
	//   opType: 'table_info',
	//   params: [],
	// });

	// console.log('tables', tablesApi.data);

	return (
		<div>
			<Form<formDataType>
				form={form}
				initialValues={formState}
				onValuesChange={(all) => {
					setFormState(all);
				}}
				onFinish={async (all) => {
					console.log();
					try {
						const mm = await writeContent.mutateAsync(
							getSource(constActionType, all),
						);
						console.log(mm, 'success');
					} catch (err) {
						console.log(err);
					}
				}}
			>
				<Row>
					<Col>
						<Form.Item name="tableName" label="tableName">
							<Input />
						</Form.Item>
					</Col>

					<Col>
						<Form.Item name="keyField" label="keyField">
							<Input />
						</Form.Item>
					</Col>
					<Col>
						<Form.Item name="statusField" label="statusField">
							<Input />
						</Form.Item>
					</Col>
					<Col>
						<Form.Item name="filePath" label="filePath">
							<Input />
						</Form.Item>
					</Col>
				</Row>

				<Form.Item>
					<Button type="primary" htmlType="submit">
						submit
					</Button>
				</Form.Item>

				<Form.Item shouldUpdate>
					{(f) => {
						return getSource(constActionType, f.getFieldsValue(true)).files.map(
							(i) => {
								return (
									<div key={i.filename}>
										<div>filename: {i.filename}</div>
										<pre>content: {i.content}</pre>
									</div>
								);
							},
						);
					}}
				</Form.Item>
			</Form>
		</div>
	);
}
